import axios from "axios";
import store from "@/store/";
export default {
  uploadImagem(file) {
    return axios.post(`/v1/usuario-manager/upload-foto-sem-usuario`, file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  updateUserPhoto(file) {
    let id = store.getters["gestao/getUser"].id;
    return axios.post(`/v1/usuario-manager/${id}/upload-foto`, file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  updatePasswordUser(payload) {
    return axios.post(`/v1/usuario-manager/update-senha`, {
      email: payload.email,
      fotoUrl: payload.fotoUrl,
      id: payload.id,
      nome: payload.nome,
      role: payload.role,
      senha: payload.senha
    });
  },
  loginGestao(mail, pass) {
    return axios.post("/v1/auth/login", { username: mail, password: pass });
  },
  getUser() {
    return axios.get(`/v1/usuario-manager/me`);
  },
  getPaginationUsers(pageNumb, pageSize, filtros) {
    return axios.post("/v1/usuario-manager/pagination", {
      descending: true,
      filtros: {
        email: filtros.email,
        nome: filtros.nome,
        role: filtros.role
      },
      pageNo: pageNumb,
      pageSize: pageSize,
      sortBy: "string"
    });
  },
  setNovoUsuarioAdministrativo(payload) {
    return axios.post("/v1/usuario-manager", {
      email: payload.email,
      fotoUrl: payload.fotoUrl,
      nome: payload.nome,
      role: payload.role,
      senha: payload.senha,
      agenciaId: payload.agenciaId,
      agenciaNome: payload.agenciaNome
    });
  },
  updateUsuarioAdministrativo(payload) {
    return axios.post(`/v1/usuario-manager/${payload.id}`, {
      email: payload.email,
      fotoUrl: payload.fotoUrl,
      id: payload.id,
      nome: payload.nome,
      role: payload.role,
      senha: payload.senha,
      agenciaId: payload.agenciaId,
      agenciaNome: payload.agenciaNome
    });
  }
};
